<template>
  <el-card>
    <div slot="header">系统任务</div>
    <el-row :gutter="20">
      <el-col :span="6"
        ><el-input
          v-model="queryForm.keyworld"
          placeholder="请输入系统任务相关信息进行筛选"
          clearable
      /></el-col>
      <el-col :span="7">
        <el-date-picker
          size="small"
          v-model="timer"
          type="datetimerange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-col>
      <el-button style="margin-left:60px;" type="primary" @click="getList">查询系统任务</el-button>
      <el-button type="primary" style="float: right" @click="openAdd"
        >新增系统任务</el-button
      >
    </el-row>
    <el-table v-loading="loading" :data="list">
      <el-table-column align="center" type="index" label="#" width="40"></el-table-column>
      <el-table-column align="center" prop="taskName" label="任务名称"></el-table-column>
      <el-table-column align="center" prop="phone" label="手机号"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" prop="exeTime" label="执行时间"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="openEdit(scope.row.id)"
            icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            style="color:#F56C6C"
            @click="deleteSysTask(scope.row.id)"
            icon="el-icon-delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryForm.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryForm.total"
    >
    </el-pagination>
    <!-- 新增及编辑系统任务对话框 -->
    <el-dialog
      :title="(this.addSysTaskForm.id&&this.addSysTaskForm.id!='') ? '编辑系统任务' : '新增系统任务'"
      :visible.sync="addSysTaskDialogVisabled"
      @close="addSysTaskDialogClose"
    >
      <el-form
        ref="addSysTaskFormRef"
        :model="addSysTaskForm"
        :rules="addSysTaskFormRules"
        v-loading="loading"
        label-width="100"
      >
        <el-form-item prop="taskName" label="任务名称">
          <el-input
            v-model="addSysTaskForm.taskName"
            autocomplete="off"
            placeholder="例：[x_xxxx_com SSL证书 即将过期]"
          ></el-input>
        </el-form-item>
        <el-form-item prop="messageBody" label="任务信息">
          <el-input
            v-model="addSysTaskForm.messageBody"
            autocomplete="off"
            placeholder="请输入任务信息"
          ></el-input>
        </el-form-item>
        <el-form-item prop="email" label="邮箱">
          <el-input
            v-model="addSysTaskForm.email"
            autocomplete="off"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号">
          <el-input
            v-model="addSysTaskForm.phone"
            autocomplete="off"
            placeholder="请输入11位的手机号（可以输入多个手机号，用‘,’隔开）"
          ></el-input>
        </el-form-item>
        <el-form-item prop="exeTime" label="执行时间">
            <el-col>
            <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss" v-model="addSysTaskForm.exeTime" style="width: 100%"></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addSysTaskDialogClose">取 消</el-button>
        <el-button type="primary" @click="addSysTaskConfim">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { list, GetById, createTask, updateTask, deleteTask } from '@/api/SystemTask/index'
import { timeOffset } from '@/utils/zoneTime.js'

export default {
  data() {
    let validateTaskName = (rule, value, callback) => {
      console.log(value);
      let result = value.substring(0, value.indexOf(' '))
      if(result&&result!=''){
        if(((/^[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z]{2,})+$/).test(result))||((/[\u4E00-\u9FA5]/).test(result))){
          callback(new Error('任务名称格式不对，不能包含正式的域名且域名中不能包含中文'))
        } else {
          callback()
        }
      }
      else{
        callback(new Error('任务名称填写不完整'))
      }
    }

    let validatePhone = (rule, value, callback) => {
      let result = value.split(/,|，/)
      // console.log(result)
      if(result){
        result.forEach(item => {
          // console.log(item)
          if(item.match(/^\d{11}$/)){
            callback()
          } else {
            callback(new Error('手机号输入格式不对'))
          }
        });
      }
    }
    return {
      list: [],
      loading: false,
      timer: '',
      queryForm: {
        keyworld: '',
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      addSysTaskForm: {
        taskName: '',
        messageBody: '',
        email: '',
        phone: '',
        exeTime: '',
      },
      // 表单验证规则
      addSysTaskFormRules: {
        taskName: [
          { required: true, message: '请输入系统任务名称', trigger: 'blur' },
          {
            validator: validateTaskName,
            trigger: 'blur',
          },
        ],
        messageBody: [
        //   { required: true, message: '请输入系统任务信息', trigger: 'blur' },
        ],
        email: [
        //   { required: true, message: '请输入系统任务信息', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: validatePhone,
            trigger: 'blur',
          },
        ],
        exeTime: [
          { required: true, message: '请选择执行时间', trigger: 'blur' },
        ],
      },
      addSysTaskDialogVisabled: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.loading = true
      // console.log(this.queryForm)
      if (this.timer) {
        let filterData = { ...this.queryForm }
        filterData.startTime = timeOffset(this.timer[0])
        filterData.endTime = timeOffset(this.timer[1])
        // console.log(filterData)
        let { data } = await list(filterData)
        // console.log(data)
        this.list = data.result
        this.queryForm.pageIndex = data.pageIndex
        this.queryForm.pageSize = data.pageSize
        this.queryForm.total = data.total
        this.loading = false
      } else{
        let { data } = await list(this.queryForm)

        console.log(data)

        this.list = data.result
        this.queryForm.pageIndex = data.pageIndex
        this.queryForm.pageSize = data.pageSize
        this.queryForm.total = data.total
        this.loading = false
      }
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryForm.pageSize = value
      this.getList()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryForm.pageIndex = val
      this.getList()
    },
    deleteSysTask(id){
      this.$confirm('此操作将删除当前系统任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteTask(id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getList()
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
        })
    },
    openAdd(){
      this.addSysTaskForm = {
        taskName: '',
        messageBody: '',
        email: '',
        phone: '',
        exeTime: '',
      },
      this.addSysTaskDialogVisabled = true;
    },
    async openEdit(id){
      this.addSysTaskForm = {
        taskName: '',
        messageBody: '',
        email: '',
        phone: '',
        exeTime: '',
      };
      // console.log(id)
      let {data} = await GetById(id);
      // console.log(data);
      this.addSysTaskForm = data;
      this.addSysTaskDialogVisabled = true;
    },
    //关闭新增系统任务对话框
    addSysTaskDialogClose(){
      this.$refs.addSysTaskFormRef.resetFields();
      this.addSysTaskDialogVisabled = false;
      
    },
    addSysTaskConfim(){
      this.$refs.addSysTaskFormRef.validate(async (valid) => {
        if (valid) {
          // 表单验证通过
          console.log('表单验证通过，提交数据：', this.addSysTaskForm);
          if(this.addSysTaskForm.id&&this.addSysTaskForm.id!=''){
            await updateTask(this.addSysTaskForm)
          } else{
            await createTask(this.addSysTaskForm)
          }
          this.addSysTaskDialogVisabled = false;
          this.getList();
        } else {
          // 表单验证未通过
          console.log('表单验证未通过');
          this.$message.error('表单填写不完整，请填写完整后重新提交')
        }
      });

    },
    //表单验证
    // validateForm(){
    //   this.$refs.addSysTaskFormRef.validate((valid) => {
    //     if (valid) {
    //       // 表单验证通过，执行提交逻辑
    //       console.log('表单验证通过，提交数据：', this.addSysTaskForm);
    //       return true
    //     } else {
    //       // 表单验证未通过，显示错误信息或执行相应处理逻辑
    //       console.log('表单验证未通过');
    //       return false
    //     }
    //   });
    // }
  },
}
</script>

<style lang="scss" scoped></style>
