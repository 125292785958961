import request from '@/utils/request'

export function list (data) {
  return request({
    url: 'api/SystemTask/list',
    method: 'get',
    params: data
  })
}

export function GetById(id) {
  return request({
    url: 'getById/' + id,
    method: 'get',
  })
}

export function createTask(data) {
  return request({
    url: 'api/SystemTask/Create',
    method: 'post',
    data,
  })
}

export function updateTask(data) {
  return request({
    url: 'api/SystemTask/Update',
    method: 'post',
    data,
  })
}

export function deleteTask(id) {
  return request({
    url: 'delete/' + id,
    method: 'get',
  })
}